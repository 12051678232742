import { timeout } from "q";

(function () {
  app.controller("SendInvitationsCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.inviter = {};
      $scope.event = {};
      $scope.users = [];
      $scope.first_name_error_message = "";
      $scope.last_name_error_message = "";
      $scope.user_error_message = "";
      $scope.error_message = "";
      $scope.send_type = "0"; // 0: SMS , 1: Email
      $scope.send_to = "0"; // 0: traveller , 1: Representative
      $scope.added = false;

      $scope.init = function () {};

      $scope.addUser = function () {
        if (!$scope.validateUsers()) {
          return;
        }

        var is_valid_users = $scope.users.every((user) => {
          if ($scope.send_type == 0) {
            return (
              user.invited_first_name.length > 0 &&
              user.invited_last_name.length > 0 &&
              user.invited_phone_number.length > 0
            );
          } else {
            return (
              user.invited_first_name.length > 0 &&
              user.invited_last_name.length > 0 &&
              user.invited_email.length > 0
            );
          }
        });

        if ($scope.users.length == 0 || is_valid_users) {
          $scope.users.push({
            invited_first_name: "",
            invited_last_name: "",
            invited_phone_number: "",
            invited_email: "",
          });
        }
        $scope.added = true;
        $scope.validateAddedUser($scope.added);
      };

      $scope.removeUser = function (index) {
        $scope.users.splice(index, 1);
      };

      $scope.validateAddedUser = function (added) {
        $scope.user_error_message = "";
        var result = true;
        if (!added) {
          $scope.user_error_message = "User is required!";
          result = false;
        }

        return result;
      };

      $scope.validateUsers = function () {
        $scope.first_name_error_message = "";
        $scope.last_name_error_message = "";
        $scope.error_message = "";
        var result = true;

        for (var i = 0; i < $scope.users.length; i++) {
          var user = $scope.users[i];

          if (user.invited_first_name.trim() == "") {
            $scope.first_name_error_message = "First name is required!";
            result = false;
            return;
          }
          if (user.invited_last_name.trim() == "") {
            $scope.last_name_error_message = "Last name is required!";
            result = false;
            return;
          }
          if ($scope.send_type == "0") {
            var phone = user.invited_phone_number;
            var isPhone =
              /^((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
            if (user.invited_phone_number.trim() == "") {
              $scope.error_message = "Phone is required!";
              result = false;
              return;
            } else if (!isPhone) {
              $scope.error_message = "Invalid phone number!";
              result = false;
              return;
            }
          } else {
            var email = user.invited_email;
            var isEmail =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
              );
            if (user.invited_email.trim() == "") {
              $scope.error_message = "Email is required!";
              result = false;
              return;
            } else if (!isEmail) {
              $scope.error_message = "Invalid email!";
              result = false;
              return;
            }
          }
        }

        return result;
      };

      $scope.sendInvitation = function () {
        if (
          !$scope.validateUsers() ||
          !$scope.validateAddedUser($scope.added)
        ) {
          return;
        }

        var users = [];
        if ($scope.send_type == "0") {
          users = $.grep($scope.users, function (x, i) {
            return (
              $.trim(x.invited_first_name) != "" &&
              $.trim(x.invited_last_name) != "" &&
              $.trim(x.invited_phone_number) != ""
            );
          });
        } else {
          users = $.grep($scope.users, function (x, i) {
            return (
              $.trim(x.invited_first_name) != "" &&
              $.trim(x.invited_last_name) != "" &&
              $.trim(x.invited_email) != ""
            );
          });
        }

        if (users.length > 0) {
          var opt = {
            event_id: $scope.event.id,
            users: angular.toJson(users),
            send_type: $scope.send_type,
            send_to: $scope.send_to,
          };

          $.post($scope.submit_url, opt, function (rs) {
            if (!rs.succeed) {
              if (rs.users) {
                $timeout(function () {
                  $scope.users = rs.users;
                });
              }

              if (rs.message) {
                toastr.error(rs.message);
              }
            }
          });
        }
      };
    },
  ]);
}.call(this));
